import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import loadable from '@loadable/component'
import './styles.scss'

const Section = loadable(() => import('/src/components/Section'))
const SectionHeader = loadable(() => import('/src/components/SectionHeader'))

const Awards = () => {
    const section = {
        title: "We're the Uk's #1 Life Insurance Broker, as voted by real customers",
        description:
            '<p>Providing first-class service is something we’re extremely proud of. We’re fortunate that our customers have recognised this and have voted for us in these excellent awards.</p>'
    }

    return (
        <Section variant="alt" className="c-awards">
            <SectionHeader data={section} variant="centered" />

            <div className="c-section__content">
                <div className="c-awards__image-container">
                    <StaticImage
                        src="../../images/award-protection.png"
                        alt="Insurance Choice Awards - Best Protection Insurance Broker"
                        className="c-awards__image-container-item"
                    />

                    <StaticImage
                        src="../../images/award-overall.png"
                        alt="Insurance Choice Awards - Best Overall Insurance Broker"
                        className="c-awards__image-container-item"
                    />

                    <StaticImage
                        src="../../images/award-customers.png"
                        alt="Insurance Choice Awards - Treating Customers Fairly Champion"
                        className="c-awards__image-container-item"
                    />
                </div>
            </div>
        </Section>
    )
}

export default Awards
